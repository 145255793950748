import type { ContactConfig } from '@SHARED/core/entities/WebsiteConfig';
import type { Company } from '@SHARED/core/entities/Company';
import type { IconComponent } from '@SHARED/presenters/PropertyPresenter';

import { phoneService } from '@SHARED/adapters/services/phone/phoneServiceAdapter';

import AddressIcon from '~icons/mdi/office-building-marker-outline';
import WhatsAppIcon from '~icons/mdi/whatsapp';
import MailIcon from '~icons/mdi/mail-outline';
import PhoneIcon from '~icons/mdi/phone';

export type CompanyInformationItem = {
	label: string;
	content: string;
	icon: IconComponent;
	url: string | null;
};

export class CompanyInformationPresenter {
	static getInformationItems(
		contactConfig: ContactConfig,
		company: Company
	): CompanyInformationItem[] {
		const links: CompanyInformationItem[] = [];

		const address = company.address;
		const whatsappPhone = contactConfig.whatsapp || company.phone;
		const phone = contactConfig.phone;
		const email = contactConfig.email || company.email;

		if (address) {
			links.push({
				label: 'Endereço',
				content: address,
				icon: AddressIcon,
				url: null
			});
		}

		if (phone) {
			links.push({
				label: 'Telefone',
				content: phoneService.getFormattedPhone(phone.replace('+55', ''), 'BR'),
				icon: PhoneIcon,
				url: `tel:${phoneService.getPhoneValue(company.phone)}`
			});
		}

		if (whatsappPhone) {
			links.push({
				label: 'WhatsApp',
				content: phoneService.getFormattedPhone(
					whatsappPhone.replace('+55', ''),
					'BR'
				),
				icon: WhatsAppIcon,
				url: `https://wa.me/${phoneService.getPhoneValue(company.phone)}`
			});
		}

		if (email) {
			links.push({
				label: 'E-mail',
				content: email,
				icon: MailIcon,
				url: `mailto:${email}`
			});
		}

		return links;
	}
}
