<template>
	<div>
		<HeaderSidebar
			v-if="showMenu"
			:links="contextLinks"
			:social-media-links="socialMediaLinks"
			:text-color="sidebarTextColor"
			:background-color="sidebarBackgroundColorValue"
			:show-sidebar="showSidebar"
			:is-full-page="isSidebarFullPage"
			:animation-style="sidebarAnimation"
			:items-position="sidebarItemsPosition"
			:icon-size="socialMediaIconSize?.sidebar"
			:font-size="sidebarFontSize"
			@close="toggleSidebar(false)"
			@click-link="handleLinkClick"
		/>
		<header
			class="header-container header-size header-padding"
			:class="[
				animationClasses,
				{
					'bg-transparent': isHeaderTransparent,
					'has-translated-logo-above-xl-breakpoint':
						!!movedDownLogoAboveXlBreakpoint
				}
			]"
		>
			<PaddingContainer
				class="header header-size"
				component="div"
				:use-fallback-container="shouldUseDefaultContainer"
				:padding="containerPadding"
				:data-use-custom-links-hover="!!linksHoverColor"
			>
				<template v-if="variant === 'logo-center'">
					<nav v-if="showMenu && filteredLinks.length">
						<ul>
							<HeaderLink
								v-for="link in linksToBePlacedOnTheLeft"
								:key="link.url"
								:url="link.url"
								:text="link.text"
								:target="link.target"
								:text-color="headerTextColor"
								:active="route?.path === link.url"
								class="link"
								@click-link="handleLinkClick(link, 'header')"
							/>
						</ul>
					</nav>

					<div class="logo-container logo-absolute-center">
						<NuxtLink v-bind="logoLinkProps" class="header-padding logo-link">
							<NuxtImg
								format="webp"
								:src="logo.imageUrl"
								:alt="logo?.alt || 'Logo'"
								class="logo-image"
							/>
						</NuxtLink>
						<NuxtLink
							v-if="partnerLogo"
							:to="partnerLogo.destinationUrl"
							class="header-padding"
						>
							<NuxtImg
								:src="partnerLogo.url"
								format="webp"
								alt="Parceiro Pilar"
								class="partner-logo"
							/>
						</NuxtLink>
					</div>
					<nav v-if="showMenu && filteredLinks.length">
						<ul>
							<HeaderLink
								v-for="link in linksToBePlacedOnTheRight"
								:key="link.url"
								:url="link.url"
								:text="link.text"
								:target="link.target"
								:text-color="headerTextColor"
								:active="route?.path === link.url"
								class="link"
								@click-link="handleLinkClick(link, 'header')"
							/>
							<ul class="social-media-header-links">
								<li
									v-for="socialMedia in socialMediaList"
									:key="socialMedia.url"
								>
									<SocialIcon
										class="social-media-link"
										:social-media="socialMedia"
										:icon-size="socialMediaIconSize?.header"
										target="_blank"
									/>
								</li>
							</ul>
							<li
								class="sidebar-toggler"
								:data-hide-on-desktop="shouldHideSidebarTogglerOnDesktop"
								@click="toggleSidebar(null)"
							>
								<MenuIcon />
							</li>
						</ul>
					</nav>
				</template>

				<template v-else>
					<div class="logo-container">
						<NuxtLink v-bind="logoLinkProps" class="logo-left header-padding">
							<NuxtImg
								format="webp"
								:src="logo.imageUrl"
								:alt="logo?.alt || 'Logo'"
								class="logo-image"
							/>
						</NuxtLink>
						<NuxtLink
							v-if="partnerLogo"
							:to="partnerLogo.destinationUrl"
							class="header-padding"
						>
							<NuxtImg
								:src="partnerLogo.url"
								format="webp"
								alt="Parceiro Pilar"
								class="partner-logo"
							/>
						</NuxtLink>
					</div>
					<nav v-if="showMenu && filteredLinks.length">
						<ul>
							<HeaderLink
								v-for="link in filteredLinks"
								:key="link.url"
								:url="link.url"
								:text="link.text"
								:target="link.target"
								:text-color="headerTextColor"
								:active="route?.path === link.url"
								class="link"
								@click-link="handleLinkClick(link, 'header')"
							/>
						</ul>

						<ul class="social-media-header-links">
							<li v-for="socialMedia in socialMediaList" :key="socialMedia.url">
								<SocialIcon
									class="social-media-link"
									:social-media="socialMedia"
									:icon-size="socialMediaIconSize?.header"
									target="_blank"
								/>
							</li>
						</ul>

						<span
							class="sidebar-toggler"
							:data-hide-on-desktop="shouldHideSidebarTogglerOnDesktop"
							@click="toggleSidebar(null)"
						>
							<MenuIcon />
						</span>
					</nav>
				</template>
			</PaddingContainer>
		</header>
	</div>
</template>

<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core';

import type {
	HeaderSection,
	HeaderSectionLink,
	LogoSizing
} from '@SHARED/core/entities/sections/HeaderSection';
import type {
	GlobalWebsiteSettings,
	WebsiteStyles
} from '@SHARED/core/entities/WebsiteConfig';
import type {
	ResponsiveSectionPadding,
	FontWeight,
	LetterSpacing,
	CssSize
} from '@SHARED/utils/helperTypes';
import type { SocialMedia } from '@SHARED/core/entities/SocialMedia';
import type { CompanyWithoutWebsiteConfig } from '@SHARED/core/entities/Company';
import { SocialMediaModule } from '@SHARED/core/entities/SocialMedia/module';
import { WebsiteConfigModule } from '@SHARED/core/entities/WebsiteConfig/module';

import { getCssNumberValueInPixels, getCSSColorVar } from '@SHARED/utils/style';
import { useAnimation } from '@SHARED/composables/useAnimation';
import { COLOR_SERVICE } from '@SHARED/utils/vueProvidersSymbols';
import { concatUrlPath } from '@SHARED/utils/url';
import { usePilarWebsiteUtm } from '@SHARED/composables/usePilarWebsiteUtm';

import HeaderSidebar from '@SHARED/components/molecules/HeaderSidebar.vue';
import HeaderLink from '@SHARED/components/atoms/HeaderLink.vue';
import PaddingContainer from '@SHARED/components/molecules/PaddingContainer.vue';
import SocialIcon from '@SHARED/components/atoms/SocialIcon.vue';
import MenuIcon from '~icons/mdi/menu';

defineOptions({ name: 'HeaderSection' });

type HeaderSectionProps = HeaderSection['config'] & {
	isMinisiteContext?: boolean;
};

const props = withDefaults(defineProps<HeaderSectionProps>(), {
	transparentOnTopOfPage: false,
	isSidebarFullPage: false,
	showMenu: true,
	sidebarAnimation: 'slide-left',
	sidebarItemsPosition: 'center',
	height: '6rem',
	shouldUseDefaultContainer: false,
	socialMediaIconSize: null,
	socialMediaLinks: null,
	isMinisiteContext: false,
	shouldHideSidebarTogglerOnDesktop: false,
	movedDownLogoAboveXlBreakpoint: null,
	linksFontSize: null,
	linksFontWeight: null,
	linksLetterSpacing: null
});

type Emit = {
	(e: 'click-link', value: { link: HeaderSectionLink; source: string }): void;
};

const emit = defineEmits<Emit>();

const company = useState<CompanyWithoutWebsiteConfig>('company');

const header = useState<HeaderSection>('header');

const styles = useState<WebsiteStyles>('styles');

const globalSettings = useState<GlobalWebsiteSettings>('globalSettings');

const isHeaderVisible = useState<boolean>('isHeaderVisible', () => false);

const colorService = inject(COLOR_SERVICE)!;

const height: string = header.value.config.height || props.height;

const route = useRoute();

const { y: yPosition } = useWindowScroll();

const { animationClasses, getAnimationClass } = useAnimation({
	animationStyle: 'slide-down',
	animationStatus: 'end'
});

const showSidebar = ref<boolean>(false);

const { utmParams } = usePilarWebsiteUtm({ context: 'minisite' });

const pilarWebsiteUrl =
	WebsiteConfigModule.DEFAULT_PILAR_PARTNER_LOGO_DESTINATION_URL;

const partnerLogo = computed<{ url: string; destinationUrl: string } | null>(
	() => {
		if (!globalSettings.value.pilarPartnerLogo) return null;

		const baseUrl = getPartnershipLogoBaseUrl();

		const destinationUrl =
			baseUrl === pilarWebsiteUrl ? `${baseUrl}?${utmParams.value}` : baseUrl;

		return {
			url: globalSettings.value.pilarPartnerLogo.imageUrl,
			destinationUrl
		};
	}
);

function getPartnershipLogoBaseUrl(): string {
	const configuredDestinationUrl =
		globalSettings.value.pilarPartnerLogo?.destinationUrl?.trim();

	return configuredDestinationUrl === '/'
		? pilarWebsiteUrl
		: configuredDestinationUrl || pilarWebsiteUrl;
}

const logoLinkProps = computed(() => {
	if (!props.isMinisiteContext) {
		return {
			to: props.logo.destinationUrl
		};
	}

	const url = concatUrlPath(company.value.website, props.logo.destinationUrl);
	const to = props.isMinisiteContext ? `${url}?${utmParams.value}` : url;

	return {
		to
	};
});

const containerPadding = computed<ResponsiveSectionPadding | null | undefined>(
	() => {
		if (!props.padding && !props.shouldUseDefaultContainer) {
			return { desktop: '1rem 3rem', mobile: '1rem 1.5rem' };
		}

		return props.padding;
	}
);

const contextLinks = computed<HeaderSectionLink[]>(() => {
	if (!props.isMinisiteContext) return props.links;

	return props.links.map(link => {
		const isInternalLink = link.url.startsWith('/') || link.url.startsWith('#');

		if (isInternalLink) {
			return {
				...link,
				url: concatUrlPath(company.value.website, link.url)
			};
		}

		return link;
	});
});

const filteredLinks = computed<HeaderSectionLink[]>(() =>
	contextLinks.value.filter(link => link && link.shouldShowOnMenu !== false)
);

const socialMediaList = computed<SocialMedia[] | null>(() => {
	if (!props.socialMediaLinks) return null;

	const socialMediaObjects = props.socialMediaLinks.filter(
		link => link.shouldShowOnMenu !== false
	);

	return SocialMediaModule.getValidAndUniqueSocialMediaList(socialMediaObjects);
});

const linksToBePlacedOnTheLeft = computed<HeaderSectionLink[]>(() =>
	filteredLinks.value.filter(link => link.shouldBePlacedOnTheLeft)
);

const linksToBePlacedOnTheRight = computed<HeaderSectionLink[]>(() =>
	filteredLinks.value.filter(link => !link.shouldBePlacedOnTheLeft)
);

const isHeaderTransparent = computed<boolean>(
	() =>
		!props.shouldBeAlwaysVisible &&
		yPosition.value === 0 &&
		props.transparentOnTopOfPage
);

const headerBackgroundColor = computed<string>(() =>
	getCSSColorVar(props?.backgroundColor || styles.value.appearance.background)
);

const headerTextColor = computed<string>(() =>
	getCSSColorVar(props?.textColor || styles.value.appearance.text)
);

const linksHoverColor = computed<string | null>(() =>
	props.linksHoverColor ? getCSSColorVar(props.linksHoverColor) : null
);

const sidebarBackgroundColorValue = computed<string>(() => {
	if (!props?.sidebarBackgroundColor) return headerBackgroundColor.value;

	return getCSSColorVar(props.sidebarBackgroundColor);
});

function toggleSidebar(status: boolean | null = null): void {
	showSidebar.value = status ?? !showSidebar.value;
}

function handleLinkClick(link: HeaderSectionLink, source: string) {
	emit('click-link', { link, source });
}

watch(yPosition, (newPosition, oldPosition) => {
	if (props.shouldBeAlwaysVisible) return;

	if (!oldPosition || newPosition < oldPosition) {
		animationClasses.value = getAnimationClass('end');
		isHeaderVisible.value = true;
		return;
	}

	if (newPosition < getCssNumberValueInPixels(height)) return;

	animationClasses.value = getAnimationClass('start');
	isHeaderVisible.value = false;
});

const mobileLogoWidth = computed<LogoSizing['width']>(
	() => props.logo.mobile.width || 'auto'
);

const mobileLogoHeight = computed<LogoSizing['height']>(
	() => props.logo.mobile.height || 'auto'
);

const desktopLogoWidth = computed<LogoSizing['width']>(
	() => props.logo.desktop.width || 'auto'
);

const desktopLogoHeight = computed<LogoSizing['height']>(
	() => props.logo.desktop.height || 'auto'
);

const movedDownLogoWidthAboveXlBreakpoint = computed<LogoSizing['width']>(
	() => props.movedDownLogoAboveXlBreakpoint?.width || desktopLogoWidth.value
);
const movedDownLogoHeightAboveXlBreakpoint = computed<LogoSizing['height']>(
	() => props.movedDownLogoAboveXlBreakpoint?.height || desktopLogoHeight.value
);

const partnerLogoSeparatorColor = computed<string>(() => {
	const color =
		globalSettings.value.pilarPartnerLogo?.separatorColor ||
		props?.textColor ||
		styles.value.appearance.text;

	const colorValue = styles.value.colorPalette[color];

	return colorService.transparentizeColor(colorValue, 0.5);
});

const mobilePartnerLogoWidth = computed<LogoSizing['width']>(
	() => globalSettings.value.pilarPartnerLogo?.mobile?.width || 'auto'
);

const mobilePartnerLogoHeight = computed<LogoSizing['height']>(
	() =>
		globalSettings.value.pilarPartnerLogo?.mobile?.height ||
		props.logo.mobile.height ||
		'auto'
);

const desktopPartnerLogoWidth = computed<LogoSizing['width']>(
	() => globalSettings.value.pilarPartnerLogo?.desktop?.width || 'auto'
);

const desktopPartnerLogoHeight = computed<LogoSizing['height']>(
	() =>
		globalSettings.value.pilarPartnerLogo?.desktop?.height ||
		props.logo.desktop.height ||
		'auto'
);

const DEFAULT_LINKS_FONT_WEIGHT = 300;

const linksFontSize = computed<CssSize>(
	() => props.linksFontSize?.regular || '1rem'
);
const linksFontSizeOnLargerScreens = computed<CssSize>(
	() => props.linksFontSize?.onLargerScreens || linksFontSize.value
);

// <!-- TODO: criar um helper para validar o font weight, incluindo a tipagem -->
const linksFontWeight = computed<FontWeight>(() => {
	const fontWeight = Number(props.linksFontWeight || DEFAULT_LINKS_FONT_WEIGHT);

	if (Number.isNaN(fontWeight)) return DEFAULT_LINKS_FONT_WEIGHT;
	if (!fontWeight) return DEFAULT_LINKS_FONT_WEIGHT;
	if (fontWeight % 100 !== 0) return DEFAULT_LINKS_FONT_WEIGHT;
	if (fontWeight > 900) return DEFAULT_LINKS_FONT_WEIGHT;

	return fontWeight as FontWeight;
});

const linksLetterSpacing = computed<LetterSpacing>(
	() => props.linksLetterSpacing?.regular || 'initial'
);
const linksLetterSpacingOnLargerScreens = computed<LetterSpacing>(
	() => props.linksLetterSpacing?.onLargerScreens || linksLetterSpacing.value
);
</script>

<style lang="scss" scoped>
$headerPadding: 1rem;

$partnerLogoGapMobile: 0.75rem;
$partnerLogoGapDesktop: 1.5rem;

header {
	color: v-bind(headerTextColor);
	background-color: v-bind(headerBackgroundColor);

	nav {
		display: flex;
		align-items: center;
		gap: 2rem;

		ul {
			list-style: none;
			display: flex;
			align-items: center;
			gap: 2rem;
		}
	}

	@include screen-up(xl) {
		&.has-translated-logo-above-xl-breakpoint .logo-container {
			position: absolute;
			bottom: -50%;

			.logo-image {
				width: v-bind(movedDownLogoWidthAboveXlBreakpoint);
				height: v-bind(movedDownLogoHeightAboveXlBreakpoint);
				max-height: none !important;
				max-width: none !important;
			}
		}
	}
}

.logo-container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: $partnerLogoGapMobile;

	@include screen-up(lg) {
		gap: $partnerLogoGapDesktop;
	}
}

.logo-image {
	width: v-bind(mobileLogoWidth);
	height: v-bind(mobileLogoHeight);

	@include screen-up(lg) {
		width: v-bind(desktopLogoWidth);
		height: v-bind(desktopLogoHeight);
	}
}

.partner-logo {
	width: v-bind(mobilePartnerLogoWidth);
	height: v-bind(mobilePartnerLogoHeight);
	object-fit: contain;
	max-height: calc(var(--header-height) - 2rem);
	padding-left: $partnerLogoGapMobile;

	border-left: 1px solid v-bind(partnerLogoSeparatorColor);

	@include screen-up(lg) {
		padding-left: $partnerLogoGapDesktop;
		width: v-bind(desktopPartnerLogoWidth);
		height: v-bind(desktopPartnerLogoHeight);
	}
}

.header-default {
	width: 100%;
	padding-left: 1.5rem;
	padding-right: 1.5rem;

	@include screen-up(lg) {
		padding-left: 3rem;
		padding-right: 3rem;
	}
}

.header-size {
	height: var(--header-height);
	max-height: var(--header-height);
}

.header-padding {
	padding-top: $headerPadding;
	padding-bottom: $headerPadding;
}

.header-container {
	transition: all 0.3s ease-in-out;
	position: fixed;
	display: flex;
	align-items: center;
	font-weight: 500;
	z-index: 10;
	width: 100%;
}

.header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;

	.logo-absolute-center {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		height: 100%;
	}

	.logo-absolute-center .logo-link img,
	.logo-left img {
		max-height: calc(var(--header-height) - calc($headerPadding * 2));
		object-fit: contain;
	}

	.link {
		display: none;
		font-size: v-bind(linksFontSize);
		font-weight: v-bind(linksFontWeight);
		letter-spacing: v-bind(linksLetterSpacing);
		padding: 0.75rem 0.25rem;

		@include screen-up(lg) {
			display: flex;
		}

		@include screen-up(xl) {
			font-size: v-bind(linksFontSizeOnLargerScreens);
			letter-spacing: v-bind(linksLetterSpacingOnLargerScreens);
		}
	}

	&[data-use-custom-links-hover='true'] {
		.social-media-link:hover {
			color: v-bind(linksHoverColor);
		}

		:deep(.link:not(.active):hover) {
			&::after {
				background-color: v-bind(linksHoverColor);
			}

			a {
				color: v-bind(linksHoverColor);
			}
		}
	}
}

.sidebar-toggler {
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	&:hover {
		transform: scale(1.1);
	}

	svg {
		width: 2rem;
		height: 2rem;
	}

	@include screen-up(lg) {
		&[data-hide-on-desktop='true'] {
			display: none;
		}
	}
}

.bg-transparent {
	background-color: transparent;
}

.social-media-header-links {
	display: none;
	gap: 1rem;

	@include screen-up(sm) {
		display: flex;
	}
}
</style>
