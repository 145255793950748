import type {
	ContactConfig,
	GlobalPropertyCardConfig,
	GlobalWebsiteSettings,
	WebsitePage
} from '@SHARED/core/entities/WebsiteConfig';
import { PagesModule } from '@SHARED/core/entities/pages/module';

const defaultGlobalSettings: GlobalWebsiteSettings = {
	enableBreadcrumb: false,
	enableCondos: false,
	pilarPartnerLogo: null,
	enableDynamicPropertiesListingPageMetadata: false,
	enableVisitScheduling: true, // Esta flag foi criada em critério de urgência e por conta disso as props que recebem o seu valor tem o fallback true, para os casos em que a company ainda não tenha a flag
	propertyCardConfig: {
		roomsIconsColor: null
	},
	contactConfig: {
		phone: null,
		whatsapp: null,
		email: null
	}
};

export class WebsiteConfigModule {
	static getValidWebsitePagesConfig(pages: WebsitePage[]) {
		return pages.map(page => {
			// TODO: encontrar uma maneira de fazer isso sem usar if && criar um dicionário com o nome das páginas e suas funções
			if (page.name === 'WhoWeAre') {
				return {
					...page,
					brokers: PagesModule.getWhoWeArePageValidBrokers(page.brokers)
				};
			} else if (page.name === 'PropertyPage') {
				return {
					...page,
					variant: page.variant || 'default'
				};
			}

			return page;
		});
	}

	static DEFAULT_PILAR_PARTNER_LOGO_DESTINATION_URL =
		'https://www.soupilar.com.br';

	static getGlobalWebsiteSettings(
		globalSettings?: Partial<GlobalWebsiteSettings> | null
	): GlobalWebsiteSettings {
		const propertyCardConfig: GlobalPropertyCardConfig = {
			roomsIconsColor:
				globalSettings?.propertyCardConfig?.roomsIconsColor || null
		};

		const contactConfig: ContactConfig = {
			phone: globalSettings?.contactConfig?.phone || null,
			whatsapp: globalSettings?.contactConfig?.whatsapp || null,
			email: globalSettings?.contactConfig?.email || null
		};

		return {
			...defaultGlobalSettings,
			...globalSettings,
			propertyCardConfig,
			contactConfig
		};
	}
}
