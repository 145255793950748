<template>
	<div class="company-information-item" target="_blank">
		<Component :is="icon" v-if="icon" class="item-icon" />
		<div class="item-content">
			<p class="item-label default-font">
				{{ label }}
			</p>
			<Component
				:is="component"
				:to="url"
				target="_blank"
				class="item-value default-font"
				:class="{ 'footer-link': props.url }"
			>
				{{ content }}
			</Component>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { CompanyInformationItem } from '@SHARED/presenters/CompanyInformationPresenter';
import type { Color } from '@SHARED/core/entities/WebsiteConfig';
import { getCSSColorVar } from '@SHARED/utils/style';

import { NuxtLink } from '#components';

defineOptions({ name: 'CompanyInformationItem' });

type CompanyInformationItemProps = CompanyInformationItem & {
	iconColor?: Color | null;
};

const props = withDefaults(defineProps<CompanyInformationItemProps>(), {
	iconColor: null
});

const component = computed<typeof NuxtLink | 'p'>(() =>
	props.url ? NuxtLink : 'p'
);

const iconColor = computed<CSSColorVar | 'currentColor'>(() =>
	props.iconColor ? getCSSColorVar(props.iconColor) : 'currentColor'
);
</script>

<style lang="scss" scoped>
.company-information-item {
	display: flex;
	align-items: center;
	gap: 1rem;

	.item-icon {
		width: 2rem;
		height: 2rem;

		color: v-bind(iconColor);
	}

	.item-content {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.item-label {
			font-weight: 300;
		}

		.item-value {
			font-weight: 500;
			text-transform: uppercase;
			font-size: 0.875rem;
		}
	}
}
</style>
